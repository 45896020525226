// "https://github.com/jonathantneal/postcss-short" - docs for shorthands
// @import url("https://fonts.googleapis.com/css?family=Poppins:400,500,700");

@import "vendor/reset";

@import "sprite/sprite";

// sprite icons @extend .icon-.....

// vars					
@import "partials/vars";
@import "partials/mixins";

/* devanagari */
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v9/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format('woff2');
unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v9/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v9/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2) format('woff2');
unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2) format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2) format('woff2');
unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2) format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
	font-size: 20px;
}

.main {
	transition: transform .6s ease;
	width: 100%;
	overflow-x: hidden;
}

.nowrap {
	white-space: nowrap;
}

.scrollbar {
	position: fixed;
	right: 0;
	z-index: 99999;
	display: none;
	width: 10px;
	height: 90%;
	height: calc(100% - (( 60px + 5px ) * 2));
	top: 65px;

	&_bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000;
		background-color: rgb(196, 196, 196);
		transform: skewY(-55deg);
	}

	.scroller {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 10px;
		size: 50px 30px;
		background-color: rgb(0, 0, 0);
		position: absolute;
		top: 0;
		right: 0;
		transition: transform .3s ease-out;
		transform-origin: center;

		&:before {
			position: absolute;
			content: "";
			height: 100%;
			width: 20px;
			background-color: rgb(0, 0, 0);
			top: 0;
			left: -10px;
			z-index: -1;
			transform: skewX(-20deg);
		}
	}
}

.hedgehog-styles {
	font-family: 'Poppins', Arial, Helvetica, sans-serif;
	color: #000;
	background-color: #ffffff;
	font-weight: 400;
	overflow-x: hidden;
	transform: .6s ease-out;

	// position: fixed; // test
	// transition: transform .6s ease-out; // test

	@import "partials/general";
	@import "partials/header";
	@import "partials/hero";

	// projects
	@import "partials/projects";

	// footer
	@import "partials/footer";

	// general INFO BLOCK
	.info-block {
		position: relative;
		z-index: 3;
		background-color: #ffffff;

		&.info-block_dark {
			background-color: #000;
			color: #fff;

			&.info-block_bordered {
				border-bottom-color: rgba($white, 0.2);
			}

			.info-block_borders {
				&:before, &:after {
					// background-color: rgba(#9B9B9B, 0.2);
					background-color: rgba($white, 0.2);
				}
			}
		}

		&.is-in-view {
			.info-block-item {
				opacity: 1;
				transform: translateY(0);
			}
		}

		&_borders {
			position: absolute;

			// width: calc(100% - 100px);
			// height: 100%;
			width: 100%; height: 100%;
			left: 0;

			// left: 50px;
			display: flex;
			justify-content: center;

			&:after, &:before {
				width: 1px;
				height: 100%;
				position: absolute;
				content: "";
				top: 0;
				left: 33%;
				background-color: rgba(#9B9B9B, 0.2);
			}

			&:after {
				left: 66%;
			}
		}

		.general-list {}

		.general-list-item {
			padding: 7px 0;
			display: flex;
			line-height: 1.7;

			&:before {
				content: "— ";
			}
		}

		.info-block-item-wraper {
			max-width: 100%;
		}

		.info-block-item {
			$calc-padding: calc((100vw - #{$wrap-width} + 100px) / 2);

			flex-basis: 33vw;
			max-width: 33vw;

			// min-height: 400px;
			display: flex;

			// display: inline-block;
			align-items: center;

			// opacity: 0; /// TEST
			// transform: translateY(50px) rotate3d(0, 4, 1, 50deg);
			// transform: translateY(50px);
			transition: all 0.3s ease-out;
			box-sizing: border-box;
			overflow: hidden;
			padding-left: 50px;
			padding-right: $calc-padding;
			padding-right: 60px;

			&.flex-column {
				align-items: flex-start;
			}

			// Delays on next items in a block
			// &:nth-child(2){transition-delay: .1s;}
			// &:nth-child(3){transition-delay: .2s;}

			&:not(:first-child) {
				box-sizing: border-box;
				justify-content: flex-start;
				align-items: flex-start;
				padding-top: 120px;
				padding-bottom: 120px;
			}

			&:not(:first-child):not(:last-child) {
				// padding-right: $calc-padding;
		
			}

			&:first-child {
				padding-left: $calc-padding;
				padding-left: 60px;
				padding-right: 50px;
			}

			&:last-child {
				padding-left: 50px;
				padding-right: 60px;
			}
		}

		&.info-block_bordered {
			border-bottom-style: solid;
			border-bottom-width: 1px;
			border-bottom-color: rgba(#9B9B9B, 0.2);
		}

		&.awards {
			.icon-awards {
				min-width: 50px;
				width: 50px;
				margin-right: 40px;
				overflow: hidden;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.awards-item {
				// margin: 10px 0;
				max-width: 100%;

				// flex-wrap: wrap;
				justify-content: center;

				// margin: 0 0 30px;

				// margin: 20px 0;
				min-height: 60px; // for equality
				&:not(:last-child) {
					margin-bottom: 20px;
				}

				// &:last-child {
				//   margin-bottom: 0;
				// }
		
			}

			.award-title {
				min-width: 140px;
				width: 140px;
				margin-right: 40px;
				text-transform: uppercase;
			}

			.award-quantity {
				color: #353434;
				font-size: 23px;
				position: relative;
				padding-left: 10px;
				min-width: 40px;
				display: flex;
				word-break: keep-all;
				align-items: center;

				.icon {
					margin-right: 5px;
				}
			}
		}

		&.we-do {
			.large-text {
				margin-bottom: 40px;
			}
		}

		&.prices {
			.large-text {
				margin-bottom: 40px;
			}

			// .price-list-item {
			//   padding: 7px 0;
			//   display: flex;
			//   line-height: 1.2;

			//   &:before {
			//     content: "— ";
			//   }
			// }
	
		}

		.imaged-list-item {
			padding: 15px 0;

			.emoji-icon {
				padding-right: 45px;
			}
		}
	}

	// team
	@import "partials/team";

	// contact us
	@import "partials/contact-us";

	// media queries
	@import "partials/screen-rules";

	// style incapsulation end
	.define-svg {
		opacity: 0;
		size: 0;
		overflow: hidden;
		z-index: -9;
	}
}

@media screen and (max-width: $small-desktop-screen) {
	html {
		font-size: 16px;
	}
}

// mobile
@media screen and (max-width: $mobile-screen) {
	html {
		font-size: 16px;
	}
}
