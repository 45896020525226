// small screen
$wrap-width: 1200px;

@media screen and (max-width: 1100px) {
	.hedgehog-styles .footer_item {
		// flex-basis: calc(50% - 20px);

	}
}

@media screen and (max-width: $small-desktop-screen) {
	$calc-padding: calc((100vw - #{$wrap-width} + 100px) / 2);

	@mixin calc-padding-if($width) {
		@if $width == "auto" {
			// if width is not passed, or empty do this
		}
		@else {
			display: inline-block;
			width: $width;
		}
	}

	.wrap {
		width: $wrap-width;
	}

	.info-block {
		.info-block-item {
			padding-right: 15px;
			padding-right: $calc-padding;
			padding-right: 60px;

			&:first-child {
				padding-left: 15px;
				padding-left: $calc-padding;
				padding-left: 60px;
			}
		}

		&.with-partners {
			.emoji-icon {
				padding-right: 25px;
			}
		}
	}

	.projects {
		.projects-list-item, .project-details .project-details-title {
			font-size: 7.5rem;
		}

		.projects-list {
			// @include project-transform(0.3rem, 0.3rem);
	
		}
	}

	.info-block.awards .award-title {
		min-width: 100px;
	}

	// footer
	// .footer {
	// 	.flex {
	// 		margin: 0;
	// 	}
	// 	&_item {
	// 		flex-basis: 50%;
	// 		padding: 10px 0;
	// 		justify-content: flex-start;
	// 		&:nth-child(even) {
	// 			justify-content: flex-end;
	// 		}
	// 	}
	// }
}

@media screen and (max-width: $wrap-width) and (min-width: $mobile-screen) {
	.info-block {
		$padding: 50px;

		.info-block-item {
			padding-right: $padding !important;

			&:first-child {
				padding-left: $padding !important;
			}
		}
	}
}

@media screen and (max-width: $mobile-screen) {
	.wrap {
		width: 100%;
		padding: 0 15px;
	}

	.large-text {
		font-size: 1.25rem;
	}

	.hero {
		.side-purposes_title, .typing-text-place {
			font-size: 1.5rem;
		}

		.side-purposes_title {
			width: 100%;

			.emoji-icon {
				right: 0;
			}
		}
	}

	.title {
		font-size: 2.125rem;

		&.title_pd-top {
			padding-top: 55px;
			margin-bottom: 0px;
		}
	}

	.hero {
		padding: 100px 0;
		height: auto;
		min-height: auto;
	}

	.info-block {
		padding: 50px 15px;

		.title {}

		.info-block_borders {
			display: none;
		}

		.info-block-item {
			border-left: none;
			min-height: unset;
			padding: 10px 0;
			flex-basis: 100% !important;
			max-width: 100%;
			margin-left: unset  !important;
			margin-right: unset !important;

			&:not(:first-child) {
				border-left: none;
				padding: 0;
			}

			&:first-child {
				padding-left: 0;
			}

			&.info-block-item_mobile-padding {
				padding-bottom: 20px;
			}
		}

		.flex {
			&.flex-column {
				align-items: flex-start;
			}

			&.flex-adaptive {
				flex-direction: column;
			}
		}

		&.we-do {
			.large-text {
				margin-bottom: 10px;
			}
		}

		&.prices {
			.large-text {
				margin-bottom: 10px;
			}
		}
	}

	// header
	.header {
		.navigation {
			display: none;
		}

		.burger-menu,
		.hamburger {
			display: block;
		}
	}

	//projects
	.projects {
		height: auto;
		overflow: visible;
		overflow-x: hidden;

		.projects-bg {
			height: 100%;
			display: none;
		}

		.projects-list {
			font-size: 7.5rem;
			overflow-y: visible;
			overflow: unset;
			width: 100%; height: 100%;
			transform: translate(0, 0);
		}

		.projects-list-item {
			padding: 7px 15px;
			color: $black !important;
		}

		.project-details {
			position: relative;
			height: auto;
			display: none;
			padding: 45px 15px;
			min-height: auto;
			height: auto;
			overflow-y: visible;

			.wrap {
				padding: 0;
			}

			.project-details-text {
				width: 100%;
			}

			.project-details-title {
				margin-bottom: 30px;
			}

			.project-details-link {
				font-size: 1.5rem;
			}
		}

		.projects-list-item, .project-details .project-details-title {
			font-size: 1.875rem;
		}
	}

	// contact
	.contact-us {
		padding: 50px 0;

		.btn-container {
			text-align: center;
		}

		.btn-submit {
			&:after {
				width: 100%;
			}
		}

		.input-block {
			flex-direction: column;
			align-items: flex-start;

			.input-block_input {
				color: $black;
				padding-left: 0;
				padding-right: 0;
				width: 100%;
			}

			.focus-effect {
				display: none;
			}
		}
	}

	// team
	.team-item {
		flex-basis: 100%;
		margin: 30px 0;

		&:hover {
			.team-name, .team-status {
				opacity: 1;
			}
		}
	}

	%team-placement {
		$placement: auto;

		// set 0 to place left , auto to center

		max-width: 100%;
		width: 300px;
		margin-left: $placement;
		margin-right: $placement;
		text-align: left;
	}

	.team-name {
		font-size: 1.25rem;
		margin-top: 15px;

		@extend %team-placement;
	}

	.team-status {
		@extend %team-placement;
	}

	.team-img {
		position: static;
		opacity: 1;
		margin-top: 0;
		width: 342px;
		height: 342px;
		max-width: 100%;

		@extend %team-placement;
	}

	// footer
	.footer {
		&_item {
			flex-basis: 100%;
			padding: 10px 0;
			justify-content: flex-start !important;
		}
	}
}
