.contact-us {
  padding-bottom: 110px;
  position: relative;
  background-color: #ffffff;
  z-index: 2;
}

.form-block {
  // padding-top: 50px;
  margin-top: 50px;
  position: relative;
  overflow: hidden;
}

.input-block {
  $focus-padding: 50px;

  width: 100%;
  border-bottom: 1px solid #DBDBDB;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  align-items: center;
  align-items: flex-start;

  .input-block_label {
    flex-basis: 30%;
    padding-right: calc(#{$focus-padding} + 5px);
    box-sizing: border-box;
  }

  label {
    height: 1px;
    width: 1px;
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .input-block_input {
    flex-basis: 70%;
    border: none;
    padding: 3px 10px;
    margin: 0;
    color: $black;
    background-color: transparent;
    transition: color .3s ease-out;
    min-height: 40px;
    resize: none;
    font-size: 1rem;
    font-family: 'Poppins Regular', sans-serif;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;

    &:hover {
      // color: $white;
      & ~ .focus-effect {
        transform: translateY(-10%);
        transition: transform .1s ease-out;
      }
    }

    &:focus {
      color: $white;

      // &::-webkit-input-placeholder {
      //     /* Chrome/Opera/Safari
      //     color: $white;
      // }

      // &::-moz-placeholder {
      //     /* Firefox 19+
      //     color: $white;
      // }

      // &:-ms-input-placeholder {
      //     /* IE 10+
      //     color: $white;
      // }

      // &:-moz-placeholder {
      //     /* Firefox 18-
      //     color: $white;
      // }

      & ~ .focus-effect {
        transform: translateY(-100%);
        transition: transform .3s ease-out;
      }
    }

    &:required {}

    &:valid {
      // background-color: green;
      & ~ .icon-input-ok {
        display: block;
        opacity: 1;
      }
    }

    &:invalid {
      & ~ .icon-input-error {
        display: block;
        opacity: 1;
      }
    }

    &.is-pristine {
      & ~ .icon {
        // display: none !important;
        opacity: 0;
        transform: translateX(-30px);
      }
    }

    min-height: 40px;
  }

  .icon {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0;
    transform: translateX(-0);
    transition: all .3s ease-out;

    // display: none !important;
    // display: none;
 
  }

  .focus-effect {
    position: absolute;
    background-color: $black;
    width: 70%;
    width: calc(70% + #{$focus-padding});
    height: 100%;
    right: 0;
    bottom: -100%;
    z-index: -1;
    transition: transform .3s ease-out;
    transform: translateY(0%);
  }
}

.btn-container {
  text-align: right;
  margin-bottom: 5px;
}

.btn-submit {
  background-color: transparent;
  border: none;
  position: relative;
  padding: 0;
  margin-top: 30px;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    background-color: $black;
    width: 0;
    transition: width .3s ease-out;
    left: 0;
    bottom: -2px;
  }

  &:hover:after, &:focus:after {
    width: 100%;
  }
}

.thank-you {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-size: 3.5rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: 100%;
  border-top: 10px solid #000;
  transform: translateY(100%);
  text-align: center;
  font-weight: 700;

  @media (max-width: 900px) {
    font-size: 1.5rem;
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    bottom: -100%;
    left: 0;
    position: absolute;
    background-color: #000;
  }

  &.is-visible {
    transform: translateY(0%);
    transition: transform .3s ease-out;
  }

  &.is-hiding {
    transform: translateY(-210%);
    transition: transform 1s ease-out;
  }
}
