.projects {
	$projects-height: 645px;
	$projects-height: calc( 100vh - 60px );
	$projects-height: 100vh;

	height: $projects-height;
	overflow: hidden;
	position: relative;
	transition: transform .2s ease-out;

	%mask {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: rgba($black, 0.45);
	}

	.projects-list {
		// @include project-transform(0.6vw , 0.6vw)
		// $x-translate: 0.5rem;
		// $y-translate: 0.6rem;
		// size: calc(100% + #{$x-translate}) calc(100% + #{$y-translate});
		// transform: translate(-#{$x-translate}, -#{$y-translate});
		width: 100%; height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
		padding-right: 22px;
		font-size: 0;
		list-style: none;
		position: static;
		z-index: 1;
	}

	.projects-list-item {
		display: block;
		font-weight: 700;
		font-size: 9rem;
		cursor: pointer;
		transition: color .3s ease-out;
		line-height: .8;
		line-height: 1;
		-webkit-backface-visibility: hidden;
		-webkit-transform: scale(1);
		overflow: hidden;
		text-transform: uppercase;
		padding-left: 1rem;

		&::first-letter {}
	}

	.projects-bg {
		height: $projects-height;
		background-repeat: no-repeat;
		background-color: #333;
		background-size: cover;
		background-position: center;
		position: absolute;
		width: 100%;
		z-index: -1;
		top: 0;
		left: 0;
		opacity: 0;
		transform: translateZ(0);
		transition: opacity 0.3s ease-out, background-image 0.3s ease-out;

		// transition: opacity 0.3s ease-out;

		&:after {
			@extend %mask;

			content: "";
		}
	}

	.project-details {
		height: $projects-height;
		transform: translateZ(0);

		// display: none;
		background-color: #333;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		color: $white;

		// will-change: contents;
		overflow: hidden;
		z-index: -9;
		opacity: 0;

		@media screen and (max-width: $mobile-screen) {
			width: calc(100% - 22px);
		}

		&.is-active {
			z-index: 2;
			opacity: 1;
			display: block;

			.project-details-title {
				transform: translateY(0);
				opacity: 1;
			}

			.project-details-text {
				transform: translateY(0);
				opacity: 1;
				font-size: 1rem;
			}

			.project-details-link {
				transform: translateY(0);
				opacity: 1;
			}
		}

		@mixin project-transition($time1: 1s, $time2: 1.4s) {
			transition: opacity #{$time1} ease, transform #{$time2} ease-out;

			// transition: opacity #{$time1} ease, transform #{$time2} cubic-bezier(0.165, 0.84, 0.44, 1);
		}

		.project-details-title {
			font-weight: 700;
			font-size: 180px;
			font-size: 9rem;
			margin-bottom: 110px;
			display: inline-block;
			border-bottom: 8px solid $white;
			cursor: pointer;
			text-transform: uppercase;

			// transform: translateY(-20px);
			// @include project-transition;

			line-height: 1;

			// opacity: 0;
			line-height: .8;
		}

		.project-details-text {
			margin-bottom: 35px;
			transform: translateY(40px);
			width: 66%;

			@include project-transition;

			opacity: 0;

			a {
				text-decoration: underline;
			}
		}

		.project-details-close {
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;
			display: none;
		}

		.project-details-link {
			display: block;
			font-weight: 700;
			font-size: 36px;
			font-size: 2.25rem;
			cursor: pointer;
			transform: translateY(40px);

			@include project-transition;

			opacity: 0;
			display: inline-block;
			border-bottom: 2px solid $white;
		}

		.project-details-mask {
			@extend %mask;

			z-index: -1;

			// animation:  mask-blend 20s infinite ease-out;
			// mix-blend-mode: saturation;
	
		}
	}

	@keyframes mask-blend {
		0% {
			background-color: rgba(0, 0, 0, 0);
		}

		50% {
			background-color: rgba(0, 0, 0, 1);
		}

		100% {
			background-color: rgba(0, 0, 0, 0);
		}
	}

	&.is-hovered {
		.projects-list-item {
			color: $white;
		}

		.projects-bg {
			opacity: 1;
		}
	}
}
