.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	transform: translateZ(0);

	// width: 100%;
	// height: 60px;
	size: 100% 60px;
	background-color: #fff;

	// in case of animatin'
	// transform: scale(2) translateY(-0px);
	// transform-origin: bottom;
	// transition: all .6s ease-out;
	z-index: 9;
	font-weight: 500;

	.logo {
		.st0 {
			fill: #202020;
		}

		svg {
			overflow: visible;
		}

		.the-hedgehog {
			fill: #000;
			stroke: rgb(0, 0, 0);
			transition: all .3s ease-out;
			transform: translateZ(0);
			animation: unset;
			stroke-width: 0px;
		}

		.the-hedgehog {
			&:hover {
				transition: all .3s ease-out;
				animation: hedgehog-spikes 0.6s;

				// stroke-width: 10px;
				animation-timing-function: ease-out;
			}
		}

		@keyframes hedgehog-spikes {
			0% {
				stroke-width: 0;
			}

			50% {
				stroke-width: 10px;
			}

			100% {
				stroke-width: 0;
			}
		}
	}

	.navigation {
		text-transform: uppercase;

		ul{
			display: flex;

			li{
				&:not(:first-child) {
					padding-left: 50px;
				}
			}
		}

		&_item {
			display: inline-block;
			padding: 10px 0;

			&.active {
				transition: transform 0.6s ease-out;
				transform: rotateX(720deg);
			}
		}
	}

	.burger-menu {
		display: none;

		$snappy: cubic-bezier(0.694, 0.048, 0.335, 1);
		$menu-color: #000000;

		width: 35px;
		height: 11px;
		width: 30px;
		height: 10px;
		padding: 10px 0 10px 15px;
		position: relative;
		transition: transform 0.3s $snappy;

		span {
			display: block;
			width: 100%;
			height: 3px;
			background-color: $menu-color;
			margin-top: 5px;

			&:first-child {
				margin-top: 0;
				transform: scaleX(1) translateX(-10px);
				transition: transform 0.2s $snappy;
				transition-delay: 0.4s;
			}
		}

		.other-bar {
			width: 3px;
			height: 0;
			background-color: $menu-color;
			position: absolute;
			top: 5px;
			left: 50%;
			margin-left: -2px;
			margin-left: 5px;
			transition: height 0.4s $snappy;
			z-index: 10;
		}

		&.is-opened {
			transform: rotate(45deg) translate(0px, -5px);
			transition-delay: 0.4s;

			span {
				&:first-child {
					transform: scaleX(0) translateX(0px);
					transition: transform 0.2s $snappy;
				}
			}

			.other-bar {
				height: 30px;
				transition: height 0.2s $snappy;
				transition-delay: 0.3s;
			}
		}
	}
}

.mobile-menu {
	background-color: $white;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 0;
	z-index: 8;
	box-sizing: border-box;
	text-transform: uppercase;
	font-weight: 500;
	overflow: hidden;
	transition: all .6s ease-out;
	transition: all 0.6s cubic-bezier(0.7, 0, 0.36, 0.63);

	// display: none;
	&.is-opened {
		height: 100vh;

		.mobile-menu-content {
			opacity: 1;

			// transition-delay: .4s;
			transform: perspective(600px) rotateX(0deg) rotateY(0deg) translateY(0px);

			// transform: perspective(600px) rotateX(0deg) rotateY(0deg);
			top: 0;
		}
	}

	.mobile-menu-content {
		opacity: .1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 15px;
		box-sizing: border-box;
		height: 100vh;
		transform: perspective(600px) rotateX(20deg) rotateY(-20deg) translateY(-40%);
		transition: all 0.6s ease-out;
		transition: all 0.6s cubic-bezier(0.7, 0, 0.36, 0.63);

		// transform: perspective(600px) rotateX(20deg) rotateY(-20deg);
		top: -40%;
		position: relative;
	}

	.mobile-link {
		// margin: 15px 0;
		position: relative;
		padding-left: 50px;
		padding: 10px 0 10px 50px;
		background-color: transparent;
		border: none;
		text-align: left;
		text-transform: uppercase;
		font-weight: 500;
		font-family: 'Poppins', Arial, Helvetica, sans-serif;
		margin-bottom: 20px;
		font-size: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.emoji-icon {
		display: inline-block;
		margin-right: 30px;
		position: absolute (0 * 0);
		top: 50%;
		margin-top: -0.5rem;
	}
}

//
.hamburger {
	// background-color: red;
	font: inherit;
	display: inline-block;
	overflow: visible;
	margin: 0;

	// padding: 15px;
	padding-right: 12px;
	cursor: pointer;
	transition-timing-function: linear;
	transition-duration: .15s;
	transition-duration: .3s;
	transition-property: opacity,filter;
	text-transform: none;
	color: inherit;
	border: 0;
	background-color: transparent;
	display: none;

	.hamburger-box {
		position: relative;
		display: inline-block;
		width: 30px;
		height: 14px;
	}

	.hamburger-inner,
	.hamburger-inner:after,
	.hamburger-inner:before {
		position: absolute;
		width: 30px;
		height: 3px;
		transition-timing-function: ease;
		transition-duration: .15s;
		transition-duration: .3s;
		transition-property: transform, top, width;

		// border-radius: 4px;
		background-color: #000;
	}

	// .hamburger-inner:after, {
	// 	display: block;
	// 	content: "";
	// }
	.hamburger-inner:before,
	.hamburger-inner:after {
		display: block;
		content: "";
	}

	& .hamburger-inner {
		// top: auto;
		top: 0;
		transition-delay: .13s;
		transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		transition-duration: .13s;
		transition-duration: .3s;
	}

	&.is-opened .hamburger-inner {
		// top: 15px;
		transition-delay: .22s;
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transform: translate3d(0, -10px, 0) rotate(-45deg);
		transform: translate3d(10px, 5px, 0) rotate(-45deg);
	}

	.hamburger-inner:before {
		top: 7px;
		top: 0;
		width: 0px;

		// transform: translateX(12px);

	}

	.hamburger-inner:after {
		top: 7px;
		transform: translateX(12px);

		// width: 0px;

	}

	&.is-opened .hamburger-inner:before {
		// right: 0;
		width: 30px;
		top: 0;
		transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s, width 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
		transition: all 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333);
		transform: rotate(-90deg);
	}

	& .hamburger-inner:before {
		transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
		transition: all 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333);
	}

	&.is-opened .hamburger-inner:after {
		// right: 0;
		width: 0;
		top: 0;

		// opacity: 0;
		transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s, width 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
		transition: all 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333);
	}

	& .hamburger-inner:after {
		transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
		transition: all 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333);
	}
}
