/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-awards-cross {
  background-image: url(../img/sprite.png);
  background-position: -210px -73px;
  width: 8px;
  height: 8px;
}
.icon-awwwards {
  background-image: url(../img/sprite.png);
  background-position: 0px -121px;
  width: 42px;
  height: 50px;
}
.icon-css-winner-small {
  background-image: url(../img/sprite.png);
  background-position: -100px -73px;
  width: 50px;
  height: 33px;
}
.icon-css-winner {
  background-image: url(../img/sprite.png);
  background-position: 0px 0px;
  width: 587px;
  height: 73px;
}
.icon-cssdesign {
  background-image: url(../img/sprite.png);
  background-position: -50px -73px;
  width: 50px;
  height: 46px;
}
.icon-input-error {
  background-image: url(../img/sprite.png);
  background-position: -150px -73px;
  width: 20px;
  height: 20px;
}
.icon-input-ok {
  background-image: url(../img/sprite.png);
  background-position: -190px -73px;
  width: 20px;
  height: 19px;
}
.icon-lazarev {
  background-image: url(../img/sprite.png);
  background-position: -170px -73px;
  width: 20px;
  height: 20px;
}
.icon-nectar {
  background-image: url(../img/sprite.png);
  background-position: 0px -73px;
  width: 50px;
  height: 48px;
}

@media (-webkit-min-device-pixel-ratio: 2),
       (min-resolution: 192dpi) {
  .icon-awards-cross {
    background-image: url(../img/sprite@2x.png);
    background-size: 587px 171px;
  }
  .icon-awwwards {
    background-image: url(../img/sprite@2x.png);
    background-size: 587px 171px;
  }
  .icon-css-winner-small {
    background-image: url(../img/sprite@2x.png);
    background-size: 587px 171px;
  }
  .icon-css-winner {
    background-image: url(../img/sprite@2x.png);
    background-size: 587px 171px;
  }
  .icon-cssdesign {
    background-image: url(../img/sprite@2x.png);
    background-size: 587px 171px;
  }
  .icon-input-error {
    background-image: url(../img/sprite@2x.png);
    background-size: 587px 171px;
  }
  .icon-input-ok {
    background-image: url(../img/sprite@2x.png);
    background-size: 587px 171px;
  }
  .icon-lazarev {
    background-image: url(../img/sprite@2x.png);
    background-size: 587px 171px;
  }
  .icon-nectar {
    background-image: url(../img/sprite@2x.png);
    background-size: 587px 171px;
  }
}
