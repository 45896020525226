& {
	font-family: 'Poppins', Arial, Helvetica, sans-serif;
	color: #000;
	background-color: #ffffff;
	font-weight: 400;
	overflow-x: hidden;
}

.wrap {
	width: $wrap-width;
	max-width: 100%;
	margin: 0 auto;
	position: relative;
	box-sizing: border-box;
	padding: 0 50px;
	padding: 0 60px;

	// width: 100% !important;
	width: 100%;

	.small-wrap {
		padding: 0;
	}
}

.small-wrap {
	@extend .wrap;

	width: 1200px;

	// width: 66vw;
	margin: 0 auto;
}

a {
	text-decoration: none;
	color: inherit;
}

p {
	margin: 0;
	line-height: 1.5;
	word-break: break-word;
}

svg {
	max-width: 100%;
	max-height: 100%;
}

.large-text {
	font-size: 2.2rem;
	font-weight: 700;
}

.title {
	text-transform: uppercase;
	font-size: 56px;
	font-size: 3.5rem;
	font-weight: 700;
	line-height: 1.2;

	&.title_pd-top {
		padding-top: 125px;
	}
}

.flex {
	display: flex;

	&.flex_space-betwen {
		justify-content: space-between;
	}

	&.flex-center-vertical {
		align-items: center;
	}

	&.flex-end {
		justify-content: flex-end;
	}

	&.flex-column {
		flex-direction: column;
		justify-content: center;
	}
}

.icon {
	&.icon_inline {
		display: inline-block;
	}
}

.emoji-icon {
	display: inline-block;
	padding-right: 5px;
	max-height: 100%;

	// font-family: 'OpenSansEmoji';
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	transition: color .3s ease-out;
	font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

::-moz-placeholder {
	/* Firefox 19+ */
	transition: color .3s ease-out;
	font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

:-ms-input-placeholder {
	/* IE 10+ */
	transition: color .3s ease-out;
	font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

:-moz-placeholder {
	/* Firefox 18- */
	transition: color .3s ease-out;
	font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

.hidden {
	opacity: 0;
	height: 0;
	overflow: hidden;
	width: 0;
	z-index: -1;
	position: relative;
}
