.team {
	background-color: $gray;
	position: relative;

	// height: 820px;
	padding-bottom: 100px;

	.title {
		margin-bottom: 100px;
	}
}

.team-list {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.team-item {
	flex-basis: 50%;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	margin: 100px 0;
	max-width: 100%;
	position: relative;

	&:hover {
		.team-img {
			opacity: 1;
		}

		.team-name, .team-status {
			opacity: 0;
		}
	}
}

.team-name {
	text-align: center;
	font-size: 2.25rem;
	font-weight: 700;
	line-height: 1.6;
	position: relative;

	.emoji-icon {
		padding-left: 10px;
		position: absolute;
	}
}

.team-status {
	text-align: center;

	// font-size: 20px;
}

.team-img {
	$size: 342px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: calc(-1 * $size / 2);
	margin-top: calc(-1 * $size / 2);
	opacity: 0;
	width: $size;
	height: $size;
	background-size: cover;
	background-position: center;
}
