.hero {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding-top: 160px;
	padding-bottom: 110px;
	padding: 200px 0 150px;
	min-height: 100vh;

	.title {
		margin-bottom: 80px; // was 60

	}

	.small-wrap {
		width: 66vw;
		margin: 0;

		@media (max-width: $mobile-screen) {
			width: 100%;
		}
	}

	.side-purposes_title {
		margin-bottom: 40px;
		font-size: 2.25rem;
		font-weight: 500;
		position: relative;
		display: inline-block;

		// width: 100%;
		padding-right: 30px;
		box-sizing: border-box;
		max-width: 100%;

		// display: none;

		// overflow: hidden;

		.emoji-icon {
			position: absolute;
			top: 50%;
			margin-top: -0.8rem;
			opacity: 0;
			right: 0;
			right: -55px;
			transform: translateX(-20px);

			// transition: opacity .2s ease-out, transform .3s ease-out;

			// left: 600px;

			&.is-visible {
				opacity: 1;
				transform: translateX(0);
				transition: opacity .8s ease-out, transform .6s ease-out;
			}
		}

		&:after {
			content: "click to read next";
			content: attr(data-help);
			font-size: .4em;
			color: #ccc;
			color: #9b9b9b;
			position: absolute;
			top: calc(100% + 10px);
			left: 0;
		}
	}

	.typing-text-place {
		font-size: 2.25rem;
		font-weight: 500;
		word-break: break-all;
		text-decoration: underline;
		text-decoration-skip-ink: auto;
		padding-left: 1rem;
	}

	.side-purposes {
		cursor: pointer;

		// font-size: 36px;

	}

	.side-purposes_descriptions {
		position: relative;
		height: 200px;
	}

	.side-purposes_description {
		// font-size: 16px;
		position: absolute;
		top: 0;
		left: 0;

		// display: none;
		opacity: 0;
		transform: translateX(-50px);

		&.is-active {
			display: block;
			opacity: 1;
			transform: translateX(0px);
			transition: all .6s ease-out;
		}
	}
}
