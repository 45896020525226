.footer {
	font-size: 0;

	.flex {
		min-height: 40px;
		padding: 10px 0;
		margin: 0 -10px;
		justify-content: space-between;

		// @media screen and (max-width: $mobile-screen) {

		@media (max-width: $oldsmall-desktop-screen) {
			flex-wrap: wrap;
			margin: 0;
		}
	}

	.emoji-icon {
		padding-right: 15px;
	}

	&_item {
		// flex-basis: 25%;
		display: flex;
		align-items: center;
		padding: 10px;
		flex-basis: calc(25% - 20px);
		font-size: 1rem;

		&-light {
			color: #9B9B9B;
		}

		&-center {
			justify-content: center;
		}

		&-right {
			justify-content: flex-end;
		}

		$item: &;

		@media (max-width: $oldsmall-desktop-screen) {
			flex-basis: calc(50% - 20px);
			padding: 10px 0;
			justify-content: flex-start;
			&:nth-child(even) {
				justify-content: flex-end;
			}
		}

		@media (max-width: 1010px) {
			flex-basis: calc(50% - 20px);
		}
	}
}
