// vars
$wrap-width: 1700px;

$oldsmall-desktop-screen: 1024px;
$small-desktop-screen: 1600px;
$mobile-screen: 900px;

$black: #000;
$white: #fff;
$gray: #ccc;
